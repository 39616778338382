type MinCosignmentProps = {
  lineItemIds: string[]
}
type MinItemProps = {
  id: string
}

const linkItemToConsignment = (
  consignments: MinCosignmentProps[],
) => (
  item: MinItemProps,
) => ({ // Adds reference to the consignment
  ...item,
  consignment: consignments.find(({ lineItemIds }) => lineItemIds.includes(item.id)),
})

const itemBelongsToConsignment = (
  consignmentLineItemIds: string[],
) => (item: MinItemProps) => consignmentLineItemIds.includes(item.id)

const linkConsignmentToItem = <T extends MinCosignmentProps>(
  lineItems: MinItemProps[],
) => (consignment: T) => ({ // Adds reference to lineItems
    ...consignment,
    lineItems: lineItems?.filter(itemBelongsToConsignment(consignment.lineItemIds)),
  })

export const hashMapProductsAndConsignments = <
T extends MinCosignmentProps,
K extends { lineItems: MinItemProps[] },
>({
    cart,
    consignments,
  }: {
    cart: K
    consignments: T[]
  }) => ({
    newCart: cart.lineItems?.map(linkItemToConsignment(consignments)),
    newConsignments: consignments?.map(linkConsignmentToItem(cart.lineItems)),
  })

export default hashMapProductsAndConsignments
