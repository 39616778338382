import { BOPUS_FIELD } from '@/services/Configuration'

const customFieldIsBopus = ({ fieldId, fieldValue }:{
  fieldId: string
  fieldValue: string
}) => (
  fieldId === BOPUS_FIELD
  && fieldValue === '1'
)

export const isBopusConsignment = (consignment: {
  shippingAddress?: {
    customFields?: {
      fieldId: string
      fieldValue: string
    }[]
  }
} |
Array<{
  fieldId: string
  fieldValue: string
}>) => {
  const customFields = (
    Array.isArray(consignment)
      ? consignment
      : consignment?.shippingAddress?.customFields
  )
  return !!customFields?.find(customFieldIsBopus)
}

export default isBopusConsignment
