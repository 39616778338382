/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import type { Product } from '@/types/Product'
import { getActualPrice } from '@/helpers/checkoutHelpers/getActualPrice'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { error } from '@/services/Log'

export type GaProduct = {
  name: string
  id: string
  price: number
  salePrice: number
  brand: string
  category: string
  variant: number
  quantity: number
}

export type MinimalLineItemProps = {
  productId: number,
  name: string,
  originalName?:string,
  sku: string,
  quantity: number,
  salePrice: number,
  discounts: Array<{ discountedAmount: number }> | { coupon: number } | undefined,
  variantId: number,
}
export const gaProducts = async <T extends MinimalLineItemProps>(
  lineItems: T[],
): Promise<GaProduct[]> => {
  // Todo: The logic needs some better handling of categories and perhaps search by Skus
  const [{ getProductById }] = await allPromisesWithRetries(() => [
    import('@/services/Product/getProductById'),
  ])
  // Also the array map is being used as a forEach
  const ids = lineItems.filter((p) => !!p.productId && !p.sku.includes(';xtd;')).map((item) => String(item.productId))
  // getProductsById
  const data = (await getProductById<{ category_info: Product['category_info'] }>({
    ids,
    _source: 'data.category_info',
  }).catch((e) => {
    error('gaProducts::Failed to fetch products::', e)
    return {}
  }))
  const categoryName = (id: string) => {
    try {
      return data?.[id]?.category_info.sort((a, b) => {
        const pathDiff = b.path.split('/').length - a.path.split('/').length
        return pathDiff || (b.name.length - a.name.length)
      })[0]?.name || 'N/A'
    } catch (err) {
      error(`categoryName:: Failed to find category name for product with id:${id} and data:${data}:: `, err)
    }
    return 'N/A'
  }

  return lineItems.map((item) => ({
    // List of productFieldObjects.
    name: item.originalName ?? item.name,
    id: item.sku,
    price: item.salePrice, // original price paid for product before discount
    salePrice: getActualPrice(item), // net price paid for product per quantity
    brand: 'ZGallerie',
    category: item?.productId ? categoryName(String(item.productId)) : 'N/A',
    variant: item.variantId, // "Small" | "Large" | etc
    quantity: item.quantity,
  }))
}

export default gaProducts
