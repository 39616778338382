import { hashMapProductsAndConsignments } from '@/helpers/hashMapProductsAndConsignments'
import Checkout from '@/services/Checkout'
import ExtendWarranty from '@/services/ExtendWarranty'
import { checkAddedExtendWarrantyItemsGA } from '@/helpers/checkAddedExtendWarrantyItemsGA'
import getCart from "@/services/FramedCheckout/orderServices/getCart";

const itemToBigCommerceLineItems = ({ quantity, id: itemId }) => ({ quantity, itemId })

export const checkAndShiftCustomItemsConsignments = async ()=>{
  // Get current custom items and cross check it with their items warranty
  let cart = await getCart()
  let { consignments } = await Checkout.getCheckout({ cartId: cart.id })
  const { customItems } = cart.lineItems
  const hashMapped = hashMapProductsAndConsignments({ consignments, cart }); // helper that links consignments to its lineItems and vice versa
  cart = hashMapped?.newCart
  consignments = hashMapped?.newConsignments
  const getConsignmentWithProductForthisExtendWarraty = extendWarrantyProduct => consignments.find(({ lineItems })=>(
    !!lineItems.find((product)=> `${extendWarrantyProduct.sku}`.split(';')[2]===product.sku)
  ))
  const affectedConsignmentsForCustomItems = (
    customItems?.map((customItem)=> ({
      item: customItem,
      expectedConsignment: getConsignmentWithProductForthisExtendWarraty(customItem),
      currentConsignment: customItem?.consignment,
      shouldMove: getConsignmentWithProductForthisExtendWarraty(customItem)?.id !== customItem?.consignment?.id,
    }))
    ?.filter(({shouldMove})=>shouldMove)
  )
  if (affectedConsignmentsForCustomItems?.length) {
    const ItemsToBeMoved = [...affectedConsignmentsForCustomItems?.map(({item})=> item)]
    const ItemIdsToBeMoved = ItemsToBeMoved?.map(({id})=>id)
    const newCurrentConsignments = affectedConsignmentsForCustomItems.map(({currentConsignment})=>({
      lineItems: (
        currentConsignment?.lineItems
        ?.filter(({id})=> !ItemIdsToBeMoved?.includes(id))
        ?.map(itemToBigCommerceLineItems)
      ),
      shippingAddress: currentConsignment?.shippingAddress,
    }))
    const newIncomingConsignments = affectedConsignmentsForCustomItems.map(({expectedConsignment:consignment})=>({
      lineItems: (
        [
          ...consignment?.lineItems,
          ...(
            affectedConsignmentsForCustomItems
            ?.filter(({expectedConsignment})=> expectedConsignment?.id === consignment?.id)
            ?.map(({item})=>item)
          )
        ]?.map(itemToBigCommerceLineItems)
      ),
      shippingAddress: consignment?.shippingAddress,
    }))
    const newConsignments = [ ...newCurrentConsignments, ...newIncomingConsignments ]
    await Checkout.createConsignments(cart.id, newConsignments)
  }
}

export const addExtendWarrantyToProduct = async ({ quantity, shouldShowExtendModal = true }) =>{
  // Displays a modal to the customers that may or may not add a custom item to the cart as a warranty
  // Then we shift check and make sure that the warranty is in the same consignment that the item that it is extending.
  try {
    const cart = await ExtendWarranty.getCart()
    const extendWarrantyItems = cart.lineItems.customItems.filter(({ sku }) => sku.includes(';xtd;'))
    if (window.ExtendBigCommerce && window.Extend) {
      let data = await new Promise((resolve, reject) => {
        window.ExtendBigCommerce.handleAddToCart('#extend-offer', {
          quantity,
          modal: shouldShowExtendModal,
          done: (error, data) => {
            if (error) {
              reject(error)
            } else {
              resolve(data)
            }
          },
        })
      })
      await checkAndShiftCustomItemsConsignments()
      await ExtendWarranty.normalize()
      const newCart = await ExtendWarranty.getCart()
      const newExtendWarrantyItems = newCart.lineItems.customItems.filter(({ sku }) => sku.includes(';xtd;'))
      checkAddedExtendWarrantyItemsGA(newExtendWarrantyItems, extendWarrantyItems)
    }
  } catch(err) { // Failed to add extend Item
    console.error('Failed to add extend Item', err)
  }
}
