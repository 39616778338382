
import Axios from 'axios'

import { XsrfResilient } from '@/services/XsrfResilient'

export const Checkout = {
   //TO-DO: move to checkout JS later
  getCheckout: XsrfResilient(async ({ cartId, include }) => {
    return !!cartId && (await Axios.get(
      `/api/storefront/checkouts/${cartId}?include=consignments.availableShippingOptions`,
      include?.length ? { params: { include: include.join(',') } } : undefined)
    ).data
  }),
  createConsignments: XsrfResilient(async ( checkoutId, data ) => {
      let consignmentsData
      for(const dataIndex in data){
        const requestBody = [data[dataIndex]]
        consignmentsData = (await Axios.post(`/api/storefront/checkouts/${checkoutId}/consignments?include=consignments.availableShippingOptions`, requestBody)).data
      }
      return consignmentsData
  }),
}
export default Checkout
