import isEmpty from 'lodash/isEmpty'

type DiscountedAmountArray = readonly { discountedAmount: number }[]
type DiscountedAmountObject = { coupon: number }

type MinimapPropsTofetchOrderPrice = {
  salePrice: number,
  discounts?: DiscountedAmountArray | DiscountedAmountObject | undefined,
  quantity: number,
}

const isDiscountedAmountArray = (
  discounts: DiscountedAmountArray | DiscountedAmountObject | undefined,
): discounts is DiscountedAmountArray => (
  Array.isArray(discounts)
)

export const getActualPrice = <T extends MinimapPropsTofetchOrderPrice>(item: T) => {
  let price = item.salePrice
  if (!isEmpty(item.discounts)) {
    const coupon = (
      isDiscountedAmountArray(item.discounts)
        ? item.discounts?.[0]?.discountedAmount || 0
        : item.discounts.coupon || 0
    )
    price = parseFloat((item.salePrice - (coupon / item.quantity)).toFixed(2))
  }
  return price
}

export default getActualPrice
