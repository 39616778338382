import Axios from 'axios'

import { SEARCH_API_V2 } from '@/services/Configuration'

import { ClientSideCache } from '@/services/ClientSideCache'

import type { Product as ProductType } from '@/types'

const CacheBySku = ClientSideCache(({ sku }: {
  sku: string
}) => String(sku))

export const bySku = CacheBySku(async ({ sku }) => (await Axios.get<Array<ProductType>>(`${SEARCH_API_V2}/product`, { params: { skus: sku } })).data)

export default bySku
