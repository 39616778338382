import { Checkout } from '@/services/Checkout'
import { bySku } from '@/services/Product/bySku'
import Cart from '@/services/Cart'
import { ExtendWarranty } from '@/services/ExtendWarranty'
import { EXTEND_SKU } from '@/data/constants'
import UsStates from '@/data/UsStates'

import { isSameAddress } from '@/helpers/addressHelpers'
import { isBopusConsignment } from '@/helpers/isBopusConsignment'
import { addExtendWarrantyToProduct } from '@/helpers/ExtendWarranty'
import { hashMapProductsAndConsignments } from '@/helpers/hashMapProductsAndConsignments'
import { log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { BOPUS_FIELD, BOPUS_STORE_FIELD } from '@/services/Configuration'
import getCart from '@/services/FramedCheckout/orderServices/getCart'

export { gaProducts } from '@/helpers/checkoutHelpers/gaProducts'

const groupBy = (field) => (collection) => collection.reduce((hash, element) => ({
  ...hash, [element[field]]: (hash[element[field]] || []).concat(element),
}), {})
const groupByAddressOne = groupBy('address1')

const pluck = (field) => (collection) => collection.map((element) => element[field])

export const createConsignments = (addresses, items) => {
  const grouped = groupByAddressOne(addresses)
  const itemIDs = items.map((i) => i.id)
  const flattenedConsignments = Object.keys(grouped).map(
    (address) => ({ id: address, lineItems: grouped[address].map((i) => ({ itemId: i.itemID })) }),
  )
  if (flattenedConsignments.length === 1) {
    flattenedConsignments[0].lineItems = itemIDs
  }
  return flattenedConsignments
}

export const createShippingMethodUpdatePayload = pluck('selectedShippingOption')

export function scrollToTop() {
  window?.scrollTo({ top: 0, behavior: 'smooth' })
}

export function formatProductOptions(options) {
  return options.reduce((productOptions, option, i) => `${productOptions
  }${i === 0 ? ': ' : ''} ${option.value.split('~')[0]}${i !== options.length - 1 ? ',' : ''}`, '')
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return null
}

export function shippingErrorClass(addressErrors, index = 0) {
  const shippingStateError = 'We do not support shipping to Alaska and Hawaii.'
  const nonShippingStateError = addressErrors && addressErrors[index]
    ?.stateOrProvinceCode?.message.includes(shippingStateError)
  return !!addressErrors && nonShippingStateError
    && !addressErrors[index]?.city?.message
    && !addressErrors[index]?.postalCode?.message
}

const isAddedProduct = ({ productId, variantId, existingShipmentForThisStore }) => (item) => (
  productId === item.productId
  && variantId === item.variantId
  && (
    existingShipmentForThisStore
      ? (!existingShipmentForThisStore?.lineItemIds.includes(item.id))
      : true
  )
)

const itemToBigCommerceLineItems = ({ quantity, id: itemId }) => ({ quantity, itemId })

const emptyAddress = () => ({
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  address1: '',
  address2: '',
  city: '',
  stateOrProvince: '',
  stateOrProvinceCode: '',
  countryCode: '',
  shouldSaveAddress: '',
  postalCode: '',
  phone: '',
  customFields: [
    {
      fieldId: BOPUS_FIELD,
      fieldValue: '0',
    },
  ],
})
const bopusAddress = (store) => ({
  firstName: 'ZGallerie',
  lastName: (store.name || store.store_name).replace('Z Gallerie', ''),
  email: store.email || 'customerservice@zgallerie.com',
  company: 'ZGallerie',
  address1: store.addr1,
  address2: store.addr2,
  city: store.city,
  countryCode: 'US',
  stateOrProvince: UsStates.find((state) => state.abbreviation === store.state)?.name,
  stateOrProvinceCode: store.state,
  shouldSaveAddress: false,
  postalCode: store.zip_cd,
  phone: store.phone || '424.999.4626',
  customFields: [
    {
      fieldId: BOPUS_FIELD,
      fieldValue: '1',
    },
    {
      fieldId: BOPUS_STORE_FIELD,
      fieldValue: (store.store_cd).toString(),
    },
  ],
})

export const addBOPISProduct = async ({
  quantity,
  productId,
  optionId,
  onClose,
  store,
  cart,
  fetchCart,
  onCartAdded,
  variantId,
  updateHeight,
}) => {
  const data = {
    lineItems: [
      {
        quantity,
        productId,
        variantId: variantId || undefined,
      },
    ],
  }
  const shippingAddress = bopusAddress(store)
  shippingAddress.address2 = (
    shippingAddress.address2 ? `${shippingAddress.address2}_` : '_'
  )
  let checkoutId = ''

  let cartData
  if (!cart) {
    cartData = await Cart.createCart(data)
    checkoutId = cartData.id
  } else {
    checkoutId = cart.id
    const lineItems = [{
      productId,
      quantity,
      variantId: variantId || undefined,
    }]
    cartData = await Cart.storefrontAddItems(checkoutId, lineItems)
  }
  // After adding to cart we either create a new consignment or update an existing one
  const { consignments } = await Checkout.getCheckout({ cartId: checkoutId })
  const existingShipmentForThisStore = (
    consignments
      ?.find((c) => isSameAddress(shippingAddress, c.shippingAddress))
  )

  const { physicalItems } = cartData.lineItems
  let existingLineItemsInConsignment = []
  if (existingShipmentForThisStore) { // Update existing consignment
    existingLineItemsInConsignment = physicalItems.filter(
      (item) => existingShipmentForThisStore?.lineItemIds.includes(item.id),
    )
  }
  // Get newly added item to cart
  const incomingItem = physicalItems
    .find(isAddedProduct({
      productId,
      variantId: Number(variantId),
      existingShipmentForThisStore,
    }))
  // Get pre-existing item that is equal to the one added
  const sameItemAlreadyInTheConsignment = physicalItems.find((item) => (
    productId === item.productId
    && variantId === item.variantId
    && existingShipmentForThisStore?.lineItemIds.includes(item.id)
  ))

  const incomingItems = [incomingItem]
    .filter((item) => !!item && !existingLineItemsInConsignment
      .find((existingItem) => item?.id === existingItem.id))

  const lineItemsForNewConsignment = [...existingLineItemsInConsignment, ...incomingItems]
    .map(itemToBigCommerceLineItems)

  const requestBody = [{
    lineItems: lineItemsForNewConsignment,
    shippingAddress,
  }]
  await Checkout.createConsignments(checkoutId, requestBody)
  await fetchCart()

  if (!updateHeight) {
    onClose()
  }

  // pre-existing item takes precedence since the incoming item disappears
  // once added to same consignment as existing item
  const cartAddition = {
    cart_item: {
      id: (sameItemAlreadyInTheConsignment || incomingItem).id,
      product_id: productId,
      thumbnail: (sameItemAlreadyInTheConsignment || incomingItem).imageUrl,
      url: (sameItemAlreadyInTheConsignment || incomingItem).url,
    },
    quantity,
    attributeId: optionId || '',
    comingFromBOPUS: true,
  }

  onCartAdded(cartAddition)
}

const itemHasQuantity = (item) => !!item.quantity

export const regularAddToCartFlow = async ({
  productId, quantity, variantId, shouldShowExtendModal = true,
}) => {
  const cartResponse = await Cart.addVariant({ product_id: productId, quantity, variantId })
  const incomingItem = (
    cartResponse.lineItems.physicalItems.find(isAddedProduct({ productId, variantId }))
  )
  const cartAddition = {
    cart_item: {
      id: (incomingItem).id,
      product_id: productId,
      thumbnail: (incomingItem).imageUrl,
      url: (incomingItem).url,
    },
    quantity,
    attributeId: '',
    comingFromBOPUS: false,
  }
  let cart = await getCart()
  const cartId = cart.id
  let { consignments } = await Checkout.getCheckout({ cartId })

  const nonCustomItem = []
  const customItems = []
  cart.lineItems.forEach((item) => {
    if (item.sku.indexOf(';xtd;') > -1) {
      const newItem = { ...item }
      newItem.imageUrl = '/__statics/images/extend_logo.png'
      newItem.isWarranty = true
      newItem.extendedSalePrice = item.extendedListPrice
      newItem.options = []
      customItems.push(newItem)
    } else {
      nonCustomItem.push(item)
    }
  })

  // helper that links consignments to its lineItems and vice versa
  const hashMapped = hashMapProductsAndConsignments({ consignments, cart })
  cart = hashMapped?.newCart
  consignments = hashMapped?.newConsignments

  const matchingItemInFirstConsignment = consignments?.[0]?.lineItems
    .find(isAddedProduct({ productId, variantId }))
  const newShipmentShippingAddress = emptyAddress()
  let newConsignments = false

  if (!consignments.length) {
    newConsignments = [{
      lineItems: [
        ...nonCustomItem,
        ...customItems,
      ]?.map(itemToBigCommerceLineItems),
      shippingAddress: newShipmentShippingAddress,
    }]
  } else if (isBopusConsignment(consignments[0]) && !!matchingItemInFirstConsignment) {
    const preexistingQuantity = matchingItemInFirstConsignment.quantity - quantity
    const consignmentToShiftItemTo = (
      consignments.find((c) => !isBopusConsignment(c))
      || {
        lineItems: [],
        shippingAddress: newShipmentShippingAddress,
      }
    )
    // Pre existing Items
    // Extend warranty Items
    const consignmentItemsToShift = [
      // Pre existing Items
      ...consignmentToShiftItemTo?.lineItems,
      // Extend warranty Items
      ...customItems.filter(({ sku }) => (sku.split(';')[2] || 0) === matchingItemInFirstConsignment.sku),
      { // Added Items
        id: matchingItemInFirstConsignment?.id,
        quantity,
      },

    ]
    const remainingBopisConsignments = (
      consignments.filter((c, index) => isBopusConsignment(c) && index > 0)
    )
    newConsignments = [
      {
        lineItems: (
          consignmentItemsToShift
            ?.map(itemToBigCommerceLineItems)
            ?.filter(itemHasQuantity)
        ),
        shippingAddress: consignmentToShiftItemTo?.shippingAddress,
      },
      { // Replaces existing Bopis consignments
        lineItems: (
          consignments?.[0]?.lineItems
            ?.map((item) => ({
              ...item,
              quantity: isAddedProduct({
                productId,
                variantId,
              })(item) ? preexistingQuantity : item.quantity,
            }))
            ?.filter(itemHasQuantity)
            .map(itemToBigCommerceLineItems)
        ),
        shippingAddress: consignments?.[0]?.shippingAddress,
      },
      ...remainingBopisConsignments.map(({ shippingAddress, lineItems }) => ({
        lineItems: lineItems.map(itemToBigCommerceLineItems),
        shippingAddress,
      })),
    ]
  }

  if (newConsignments) {
    await Checkout.createConsignments(cartId, newConsignments)
  }
  const isExtendEnabledProduct = !!document.getElementById('extend-offer')
  if (isExtendEnabledProduct) {
    await addExtendWarrantyToProduct({ quantity, shouldShowExtendModal })
  }
  const updatedCart = await ExtendWarranty.getCart() || await getCart()
  try {
    const [
      { listrakSubmitCart },
    ] = await allPromisesWithRetries(() => [
      import('@/services/Tracking/Listrak'),
    ])
    await listrakSubmitCart(updatedCart?.lineItems?.physicalItems)
  } catch (lisTrakError) {
    console.error(`lisTrakError ${JSON.stringify(lisTrakError)}`)
  }
  return { updatedCart, cartAddition }
}

export const bounceXPixel = async (
  order,
  lineItems,
  itemsSubtotal,
  shippingCost,
  taxTotal,
  grandTotal,
) => {
  const {
    orderId, email, billingAddress, coupons, discountAmount,
  } = order
  window.top.bouncex = window.top.bouncex || []
  window.top.bouncex.push(['conversion', {
    order_id: orderId,
    email,
    phone: billingAddress.phone,
    goal: 'purchase',
    transaction_origin: 'online',
    currency: 'USD',
    coupon: [coupons[0]?.code || ''],
    total_discount: discountAmount + coupons[0]?.discountedAmount || 0,
    tax: taxTotal,
    shipping: shippingCost,
    amount: grandTotal,
    item: lineItems.map((lineItem) => ({
      sku: lineItem.sku,
      product_id: lineItem.id,
      price: lineItem.salePrice,
      quantity: lineItem.quantity,
    })),
  },
  ])
}

export const getProductOriginalPriceBySku = async (sku) => {
  try {
    const parentProduct = (await bySku({ sku }))[0]
    const allVariants = parentProduct.variants
    const variant = allVariants.find((v) => v.sku === sku)
    const { price } = variant
    return price
  } catch (error) {
    log('Error in getting product original price. Returning undefined', error)
    return undefined
  }
}

export const getSubtotalPrice = ({ price, quantity }) => parseFloat((price * quantity).toFixed(2))

export const EXTEND_LOGO = 'https://cdn11.bigcommerce.com/s-whvhnri10h/product_images/uploaded_images/extend-logo.png'

export const getCustomItems = async ({ lineItems, customItems }) => {
  // This was wrongfully copied over SF-SSR,
  // if this breaks your code please remove this line and use the correct import
  return Promise.resolve([])
  // eslint-disable-next-line no-unreachable
  const [{ checkIsExtendItem }] = await allPromisesWithRetries(() => [
    import('@/helpers/checkIsExtendItem'),
  ])

  return customItems
    .map((item) => {
      let imageUrl = ''
      let isFurniture = false
      let arrivalWindow = ''
      let isReturnable = false
      let isDigital = false
      let isBackOrder = false
      if (checkIsExtendItem(item)) {
        const warrantyProductId = item?.sku?.split(EXTEND_SKU)?.[1]
        const warrantyProduct = lineItems.find((lineItem) => lineItem.sku === warrantyProductId)
        isFurniture = !!warrantyProduct?.isFurniture
        imageUrl = EXTEND_LOGO
        arrivalWindow = warrantyProduct?.arrivalWindow || arrivalWindow
        isReturnable = !!warrantyProduct?.isReturnable
        isDigital = false
        isBackOrder = false
      }
      const years = parseInt(String(item?.sku?.split(EXTEND_SKU)?.[0].split('-')
        .pop()))

      return {
        ...item,
        imageUrl,
        arrivalWindow,
        isFurniture,
        originalPrice: item.listPrice,
        salePrice: item.listPrice,
        extendedSalePrice: item.listPrice,
        isReturnable,
        isDigital,
        isBackOrder,
        name: `${years} Year Extend Protection Plan`,
        subtotalPrice: getSubtotalPrice({
          price: item.listPrice,
          quantity: item.quantity,
        }),
      }
    })
}

export const isOrderConfirmationPage = () => !!`${window?.location?.href}`.match(/checkout\/order-confirmation\/([a-z0-9]*)$/gi)
